import { HotelCarSubLOBProps } from "components/flexComponents/WizardPackagePWA/typings";
import * as React from "react";
import { Location } from "src/components/shared/Location/Location";
import { Dates } from "src/components/shared/Dates/Dates";
import { EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { observer } from "mobx-react";
import { StaySomewhereElse } from "./StaySomewhereElse";

export const HotelCar = observer((props: HotelCarSubLOBProps) => {
  const halfWizard = props.globalConfig?.halfWizard;
  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 6, large: halfWizard ? 12 : 6 };
  const datesColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 6, large: halfWizard ? 12 : 6 };
  const pickUplocationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 6 };

  const { isHotelCarFormValid, updateInvalidFormsState } = props.packageWizardState;

  if (isHotelCarFormValid) {
    updateInvalidFormsState();
  }

  return (
    <>
      {!props.packageWizardState.driveSomewhereElse && (
        <Location
          setInputsRefs={props.setInputsRefs}
          originField={false}
          destinationField
          lobState={props.packageWizardState}
          colSpan={locationColSpan}
          globalConfig={props.globalConfig}
        />
      )}

      {props.packageWizardState.driveSomewhereElse && (
        <StaySomewhereElse lobState={props.packageWizardState} colSpan={locationColSpan} />
      )}

      <Dates lobState={props.packageWizardState} colSpan={datesColSpan} setInputsRefs={props.setInputsRefs} />

      {props.packageWizardState.driveSomewhereElse && (
        <Location
          setInputsRefs={props.setInputsRefs}
          originField={false}
          destinationField
          lobState={props.packageWizardState}
          colSpan={pickUplocationColSpan}
          globalConfig={props.globalConfig}
        />
      )}
    </>
  );
});
