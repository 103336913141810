import * as React from "react";
import { observer } from "mobx-react";

import { EGDSLayoutConditionalGridSpan, EGDSLayoutConditionalGridStartEnd } from "@egds/react-core/layout-grid";

import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";
import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { PackageSubLOBHotwireProps } from "../../typings";
import { MenuFieldsHotwire } from "./MenuFieldsHotwire";

export const HotelCarHotwire = observer((props: PackageSubLOBHotwireProps) => {
  const { isMultiLOB } = useMultiLOBWizardRegion();

  const {
    packageWizardState: {
      isHotelCarFormValid,
      updateInvalidFormsState,
      config: {
        travelers: { noRehydratationValues },
      },
    },
  } = props.wizardState;

  if (isHotelCarFormValid) {
    updateInvalidFormsState();
  }

  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12, large: 3 };
  const datesColStart: EGDSLayoutConditionalGridStartEnd = { small: 1, medium: 1, large: 4 };
  const datesColEnd: EGDSLayoutConditionalGridStartEnd = { small: "end", medium: 7, large: 7 };
  const travelersColStart: EGDSLayoutConditionalGridStartEnd = { small: 1, medium: 7, large: 7 };
  const travelersColEnd: EGDSLayoutConditionalGridStartEnd = { small: "end", medium: "end", large: 10 };
  const submitColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 3, large: isMultiLOB ? 3 : 2 };

  return (
    <>
      <Location
        setInputsRefs={props.setInputsRefs}
        originField={false}
        destinationField
        lobState={props.packageWizardState}
        colSpan={locationColSpan}
        globalConfig={props.globalConfig}
      />
      <Dates
        lobState={props.packageWizardState}
        colStart={datesColStart}
        colEnd={datesColEnd}
        setInputsRefs={props.setInputsRefs}
      />
      <MenuFieldsHotwire
        currentSubLOB={props.currentSubLOB}
        wizardState={props.wizardState}
        colStart={travelersColStart}
        colEnd={travelersColEnd}
        noRehydratationValues={noRehydratationValues}
      />
      <SubmitButton
        lobState={props.packageWizardState}
        rfrr="SearchClick_Package"
        colSpan={submitColSpan}
        globalConfig={props.globalConfig}
      />
    </>
  );
});
