import * as React from "react";
import { observer } from "mobx-react";

import { EGDSLayoutGridItem, EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { EGDSHeading } from "@egds/react-core/text";

import { useLocalization } from "@shared-ui/localization-context";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { PackageSubLOBProps } from "components/flexComponents/WizardPackagePWA/typings";
import { Dates } from "components/shared/Dates/Dates";
import { Location } from "components/shared/Location/Location";

import { locKeys } from "../l10n";
import { StaySomewhereElse } from "./StaySomewhereElse";

export const PackageOnewayFlight = observer(({ packageWizardState, setInputsRefs }: PackageSubLOBProps) => {
  const { formatText } = useLocalization();

  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 8 };
  const datesColSpanOneway: EGDSLayoutConditionalGridSpan = { small: 2, medium: 4 };
  const fssseColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 4 };
  const datesHotelColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 8 };

  const { isFlightHotelOneWayFormValid, updateInvalidFormsState } = packageWizardState;

  if (isFlightHotelOneWayFormValid) {
    updateInvalidFormsState();
  }

  const renderPartialDates = ({ stackedDates = false }: { stackedDates?: boolean }) => (
    <Dates
      lobState={packageWizardState}
      isPartialStay
      colSpan={datesHotelColSpan}
      stackedDates={stackedDates}
      setInputsRefs={setInputsRefs}
    />
  );

  return (
    <>
      <Location
        originField
        destinationField
        showSwapLocationsControl
        lobState={packageWizardState}
        colSpan={locationColSpan}
        onewayFlight
      />
      <Dates lobState={packageWizardState} colSpan={datesColSpanOneway} singleDate setInputsRefs={setInputsRefs} />
      <EGDSLayoutGridItem colSpan="all">
        <EGDSHeading tag="h2" size={4}>
          {formatText(locKeys.hotelSectionLabel)}
        </EGDSHeading>
      </EGDSLayoutGridItem>
      <StaySomewhereElse lobState={packageWizardState} colSpan={fssseColSpan} />
      <Viewport>
        <ViewSmall>{renderPartialDates({ stackedDates: true })}</ViewSmall>
        <ViewMedium>{renderPartialDates({})}</ViewMedium>
      </Viewport>
    </>
  );
});
