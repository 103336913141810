import { FlightType } from "src/stores/wizard/state/typings";
import { ComponentProps, FlexComponentModel } from "typings/flexFramework/FlexComponent";
import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";
import { AnalyticsStore, ContextStore, PageStore } from "bernie-plugin-mobx";
import { PackageConfig, HotelConfig } from "stores/wizard/config";
import * as React from "react";
import { PackageWizardState, ThirdPartyPackageWizardState, CarWizardState } from "stores/wizard/state";
import { EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { LocalizedComponentProps } from "@shared-ui/localization-context";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { EGDSCheckbox } from "@egds/react-core/checkbox";
import { CompositionStore } from "stores/CompositionStore";
import { ClassCodes } from "src/components/shared/PreferredClassInput/PreferredClassInput";
import { GlobalConfig } from "src/stores/wizard/config/staticConfigs/global/typings";
import { PreferredAirline } from "components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { FlexModuleModelStore } from "src/stores/FlexModuleModelStore";
import { OffsetDatesOptions } from "src/stores/wizard/config";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";

export type onSubLOBSelection = (pwaSubLOB: string) => () => void;

export enum PackageTab {
  BundledPackageTab = "packages",
  AllInclusiveTab = "all-inclusive",
}

export interface WizardPackagePWAFlexModelResult extends FlexComponentModel, OffsetDatesOptions {
  fixedLobTitle: boolean;
  lobSubtitleBreakPoint?: string;
  hideLobTitle?: boolean;
  titleFromPageHeader: boolean;
  include3PP: boolean;
  includePageHeading: boolean;
  includePageSubHeading: boolean;
  defaultPackageSubLOB: string;
  packageTypes: string[];
  countryCode?: string;
  allInclusiveDefaultCountryCodes?: string[];
  view?: string;
}

export interface WizardPackagePWATemplateComponent extends ExtendedTemplateComponent {}

export interface WizardPackagePWAProps extends ComponentProps {
  templateComponent: WizardPackagePWATemplateComponent;
  flexModuleModelStore: FlexModuleModelStore;
  wizardState: ClientWizardStateStore;
  analytics?: AnalyticsStore;
  compositionStore?: CompositionStore;
  page?: PageStore;
}

export interface WizardPackagePWAViewState {
  currentTab: string;
}

export interface WizardPackagePWAViewProps extends WizardPackagePWAProps, LocalizedComponentProps {
  setFlightType: () => string;
  togglePartialStay: () => void;
  defaultPackageType: LobCombinations;
  onSubLOBSelection: (pwaSubLOB: string) => () => void;
  onFlightTypeUpdate: (flightType: FlightType) => void;
  onSelectPreferredClassCode: (classCode: ClassCodes) => void;
  onSelectPreferredAirline: (flightAirline: PreferredAirline) => void;
  packageSubLOBs: Map<string, string[]>;
  getLocError?: (isLink: boolean) => string | undefined;
  setInputsRefs?: (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => void;
  defaultTab: string;
  page?: PageStore;
}

export interface PackageSubLOBProps extends WizardPackagePWAProps {
  packageType?: string;
  packageConfig: PackageConfig;
  packageWizardState: PackageWizardState;
  togglePartialStay: () => void;
  setFlightType?: (selectEvent: React.ChangeEvent) => void;
  setInputsRefs?: (ref: React.RefObject<HTMLInputElement>) => void;
  globalConfig?: GlobalConfig;
  carWizardState?: CarWizardState;
}
export interface PackageSubLOBHotwireProps extends PackageSubLOBProps {
  wizardState: ClientWizardStateStore;
  currentSubLOB: string;
}

export interface HotelCarSubLOBProps extends Omit<PackageSubLOBProps, "displayPreferredClassCode"> {}

export interface PackageHiddenSearchDataProps {
  pwaSubLOB: string;
  packageWizardState: PackageWizardState;
  flightClass: string;
  flightAirline: PreferredAirline;
  leg1: string;
  leg2?: string;
  config: PackageConfig;
  hotelConfig?: HotelConfig;
}

export interface PackageHiddenSearchDataHotwireProps {
  pwaSubLOB: string;
  config: PackageConfig;
  packageWizardState: PackageWizardState;
}

export interface StaySomewhereElseProps {
  lobState: PackageWizardState;
  context?: ContextStore;
  colSpan?: EGDSLayoutConditionalGridSpan;
}

export interface PackagePartialStayProps {
  partialStayAccommodationLabel: string;
  togglePartialStay: () => void;
  packageWizardState: PackageWizardState;
}
export interface PackagePreferredClassProps {
  packageWizardState: PackageWizardState;
  setFlightClass: (selectEvent: React.ChangeEvent) => void;
}

export type LobType = "f" | "h" | "c";
export type LobCombinations = "fh" | "fhc" | "fc" | "hc";

export interface ILOBCheckbox {
  label: string;
  enhancedLabel: string;
  enhancedVariantLabel: string;
  altLabelAdd: string;
  altLabelRemove: string;
  checked: boolean;
  ref: React.RefObject<EGDSCheckbox>;
  id: string;
  type: LobType;
}

export interface ILOBMap {
  flights: ILOBCheckbox;
  hotels: ILOBCheckbox;
  cars: ILOBCheckbox;
}

export interface IPackageType {
  checked: boolean;
  type: LobType;
}

export interface IPackageTypeMap {
  flights: IPackageType;
  hotels: IPackageType;
  cars: IPackageType;
}

export interface PackageExperiment {
  control: boolean;
  variant1: boolean;
}

export interface PackageLegInformation {
  originTla: string;
  originId: string;
  destinationTla: string;
  destinationId: string;
  startDate: string;
  endDate: string;
}

export interface ThirdPartyPackageProps extends Omit<WizardPackagePWAProps, "context"> {
  thirdPartyPackageWizardState: ThirdPartyPackageWizardState;
  context?: ExtendedContextStore;
  getLocError?: (isLink: boolean) => string | undefined;
  setInputsRefs?: (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => void;
}

export interface OffersWithTransferToggleProps {
  lobState: ThirdPartyPackageWizardState;
}

export interface TypeMenuProps {
  context: ExtendedContextStore;
  lobsMap: ILOBMap;
  useLink: boolean;
  className: string;
  lobKeys: string[];
  handleMenuChange: (
    lobName: string,
    lobCheckbox: React.RefObject<any>,
    checkboxId: string,
    forcePillState?: boolean
  ) => void;
}

export interface TabsLabels {
  packages: string;
  "all-inclusive": string;
}
