import { PackageSubLOBProps } from "components/flexComponents/WizardPackagePWA/typings";
import * as React from "react";
import { Location } from "src/components/shared/Location/Location";
import { Dates } from "src/components/shared/Dates/Dates";
import { observer } from "mobx-react";
import { EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";

export const FlightCar = observer((props: PackageSubLOBProps) => {
  const halfWizard = props.globalConfig?.halfWizard;
  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12, large: halfWizard ? 12 : 8 };
  const datesColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12, large: halfWizard ? 12 : 4 };
  const { isFlightCarFormValid, updateInvalidFormsState } = props.packageWizardState;

  if (isFlightCarFormValid) {
    updateInvalidFormsState();
  }

  return (
    <>
      <Location
        setInputsRefs={props.setInputsRefs}
        originField
        destinationField
        lobState={props.packageWizardState}
        colSpan={locationColSpan}
        showSwapLocationsControl
        globalConfig={props.globalConfig}
      />
      <Dates lobState={props.packageWizardState} colSpan={datesColSpan} setInputsRefs={props.setInputsRefs} />
    </>
  );
});
