import * as React from "react";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { ILOBCheckbox, ILOBMap, TypeMenuProps } from "../../typings";
import { EGDSPill } from "@egds/react-core/pill";
import { EGDSSpacing } from "@egds/react-core/spacing";

interface TypeMenuPillsProps extends TypeMenuProps {
  showMulticity: boolean;
  showOneway: boolean;
  showEnhancedLabels: boolean;
}

export const TypeMenuPillsHotwire = ({
  handleMenuChange,
  lobsMap,
  className,
  useLink,
  lobKeys,
}: TypeMenuPillsProps) => {
  const renderPill = (key: string, { checked, ref, id, label }: ILOBCheckbox) => {
    const checkedIconWithText = (
      <>
        <EGDSIcon name="check_circle" size={EGDSIconSize.SMALL} /> {label}
      </>
    );
    const overridenLabel = checked ? checkedIconWithText : label;
    const activePillStyle = checked ? "active" : "";

    const onChangePill = () => {
      handleMenuChange(key, ref, id);
    };

    return (
      <EGDSPill
        key={key}
        id={!useLink ? `package-pills-${key}` : ""}
        checked={checked}
        active={checked}
        className={activePillStyle}
        onChange={onChangePill}
        children={overridenLabel}
      />
    );
  };

  const pillsToLoad = () => {
    return lobKeys.map((lobKey) => {
      const lobCheckbox: ILOBCheckbox = lobsMap[lobKey as keyof ILOBMap];
      const pillToLoad = renderPill(lobKey, lobCheckbox);

      if (useLink) {
        return (
          <EGDSSpacing margin={{ inline: "one" }} key={lobKey}>
            <button className="ssr-link-pill">{pillToLoad}</button>
          </EGDSSpacing>
        );
      }

      return pillToLoad;
    });
  };

  return (
    <EGDSLayoutFlexItem className={className}>
      <div>
        <EGDSLayoutFlex data-testid="type-menu-pills">{pillsToLoad()}</EGDSLayoutFlex>
      </div>
    </EGDSLayoutFlexItem>
  );
};
