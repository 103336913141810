import * as React from "react";

import { useLocalization } from "@shared-ui/localization-context";
import { EGDSEmptyState } from "@egds/react-core/empty-state";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";

import { PackageWizardState } from "stores/wizard/state";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";

export interface PackageErrorStateProps {
  lobsMissing?: number;
  packageWizardState: PackageWizardState;
}

export const PackageErrorStateHotwire: React.FunctionComponent<PackageErrorStateProps> = ({ packageWizardState }) => {
  const { formatText } = useLocalization();

  const heading = formatText("wizard.package.invalid.header.selectTwoOrMore.text");
  const body = formatText("wizard.package.invalid.body.toStartBuilding.text");
  const { updateInvalidFormsState } = packageWizardState;

  updateInvalidFormsState();

  return (
    <EGDSSpacing margin={{ blockend: "four" }}>
      <EGDSLayoutFlex justifyContent="center" className="error">
        <EGDSEmptyState>
          <EGDSIcon name="search" size={EGDSIconSize.LARGE} />
          <EGDSHeading tag="h2" size={7}>
            {heading}
          </EGDSHeading>
          <EGDSText>{body}</EGDSText>
        </EGDSEmptyState>
      </EGDSLayoutFlex>
    </EGDSSpacing>
  );
};
