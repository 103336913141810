import * as React from "react";
import { observer } from "mobx-react";

import { EGDSLayoutConditionalGridSpan, EGDSLayoutConditionalGridStartEnd } from "@egds/react-core/layout-grid";

import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";
import { Location } from "components/shared/Location/Location";
import { Dates } from "components/shared/Dates/Dates";
import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { PackageSubLOBHotwireProps } from "../../typings";
import { MenuFieldsHotwire } from "./MenuFieldsHotwire";

export const FlightCarHotwire = observer((props: PackageSubLOBHotwireProps) => {
  const { isMultiLOB } = useMultiLOBWizardRegion();

  const {
    packageWizardState: {
      isFlightCarFormValid,
      updateInvalidFormsState,
      config: {
        travelers: { noRehydratationValues },
      },
    },
  } = props.wizardState;

  if (isFlightCarFormValid) {
    updateInvalidFormsState();
  }

  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12, large: isMultiLOB ? 12 : 8 };
  const datesColStart: EGDSLayoutConditionalGridStartEnd = { small: 1, medium: 1, large: 1 };
  const datesColEnd: EGDSLayoutConditionalGridStartEnd = { small: "end", medium: 7, large: isMultiLOB ? 7 : 5 };
  const submitColStart: EGDSLayoutConditionalGridStartEnd = { small: 1, medium: 1, large: isMultiLOB ? 5 : 1 };
  const submitColEnd: EGDSLayoutConditionalGridStartEnd = { small: "end", medium: 7, large: isMultiLOB ? 9 : 5 };

  return (
    <>
      <Location
        setInputsRefs={props.setInputsRefs}
        originField
        destinationField
        lobState={props.packageWizardState}
        colSpan={locationColSpan}
        showSwapLocationsControl={false}
        globalConfig={props.globalConfig}
      />
      <Dates
        lobState={props.packageWizardState}
        colStart={datesColStart}
        colEnd={datesColEnd}
        setInputsRefs={props.setInputsRefs}
      />
      <MenuFieldsHotwire
        currentSubLOB={props.currentSubLOB}
        wizardState={props.wizardState}
        noRehydratationValues={noRehydratationValues}
      />
      <SubmitButton
        lobState={props.packageWizardState}
        rfrr="SearchClick_Package"
        colStart={submitColStart}
        colEnd={submitColEnd}
        globalConfig={props.globalConfig}
      />
    </>
  );
});
