import { locKeys } from "components/flexComponents/WizardPackagePWA/l10n";
import { PackageSubLOBProps } from "components/flexComponents/WizardPackagePWA/typings";
import * as React from "react";
import { observer } from "mobx-react";
import { Location } from "src/components/shared/Location/Location";
import { Dates } from "src/components/shared/Dates/Dates";
import { PackagePartialStay } from "components/flexComponents/WizardPackagePWA/components/PackagePartialStay";
import { EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

export const FlightHotelCar = observer((props: PackageSubLOBProps) => {
  const halfWizard = props.globalConfig?.halfWizard;
  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12, large: halfWizard ? 12 : 8 };
  const datesColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12, large: halfWizard ? 12 : 4 };
  const datesPartialStayColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 6, large: halfWizard ? 12 : 8 };
  const { isFlightHotelCarFormValid, updateInvalidFormsState } = props.packageWizardState;

  if (isFlightHotelCarFormValid) {
    updateInvalidFormsState();
  }

  return (
    <>
      <Location
        setInputsRefs={props.setInputsRefs}
        originField
        destinationField
        lobState={props.packageWizardState}
        colSpan={locationColSpan}
        showSwapLocationsControl
        globalConfig={props.globalConfig}
      />
      <Dates lobState={props.packageWizardState} colSpan={datesColSpan} setInputsRefs={props.setInputsRefs} />

      <PackagePartialStay
        togglePartialStay={props.togglePartialStay}
        packageWizardState={props.packageWizardState}
        partialStayAccommodationLabel={locKeys.partialStayAccommodationLabel}
      />
      {props.packageWizardState.partialStay && (
        <Viewport>
          <ViewSmall>
            <Dates
              lobState={props.packageWizardState}
              isPartialStay
              colSpan={datesPartialStayColSpan}
              stackedDates
              setInputsRefs={props.setInputsRefs}
            />
          </ViewSmall>
          <ViewMedium>
            <Dates
              lobState={props.packageWizardState}
              isPartialStay
              colSpan={datesPartialStayColSpan}
              setInputsRefs={props.setInputsRefs}
            />
          </ViewMedium>
        </Viewport>
      )}
    </>
  );
});
